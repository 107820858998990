import React from 'react';
import Terminal from './shell/Terminal';
import "./css/main.scss"
import "./css/pygments.css"
import "./css/github-markdown.css"
import {connect} from "react-redux";
import {
  closeSpecialMessage,
  setEditorContent,
} from "./book/store";
import Popup from "reactjs-popup";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faStop,
} from '@fortawesome/free-solid-svg-icons'
import "react-toggle/style.css"
import {ErrorBoundary} from "./Feedback";
import {interrupt, runCode, terminalRef} from "./RunCode";
import terms from "./terms.json"

const EditorButtons = (
  {
    disabled,
    showEditor,
    running,
  }) =>
  <div className={"editor-buttons " + (showEditor ? "" : "invisible")}>
    {
      running ?
        <button
          className="btn btn-danger"
          onClick={() => interrupt()}
        >
          <FontAwesomeIcon icon={faStop}/> {terms.stop}
        </button>
        :
        <button
          disabled={disabled}
          className="btn btn-primary"
          onClick={() => runCode({source: "editor"})}
        >
          <FontAwesomeIcon icon={faPlay}/> {terms.run}
        </button>
    }
  </div>;

const Editor = ({readOnly, value}) =>
  <div className="editor">
    <AceEditor
      mode="python"
      theme="monokai"
      onChange={setEditorContent}
      onLoad={(editor) => {
        editor.renderer.setScrollMargin(10);
        editor.renderer.setPadding(10);
      }}
      value={value}
      name="editor"
      height="100%"
      width="100%"
      fontSize="15px"
      setOptions={{
        fontFamily: "monospace",
        showPrintMargin: false,
      }}
      readOnly={readOnly}
    />
  </div>;

const Shell = () =>
  <Terminal
    onCommand={(cmd) => runCode({code: cmd, source: "shell"})}
    ref={terminalRef}
  />

class AppComponent extends React.Component {
  render() {
    return <div className="book-container">
      <NavBar user={this.props.user}/>
      <ErrorBoundary canGiveFeedback>
        <AppMain {...this.props}/>
      </ErrorBoundary>
    </div>
  }
}

function NavBar({user}) {
  return <nav className="navbar navbar-expand-lg navbar-light bg-light"></nav>;
}

function AppMain(
  {
    editorContent,
    specialMessages,
    prediction,
    running,
  }) {
  const fullIde = true;
  let showEditor;
  showEditor = true;

  const cantUseEditor = prediction.state === "waiting" || prediction.state === "showingResult";

  return <>

    <EditorButtons {...{
      showEditor,
      disabled: cantUseEditor,
      running,
    }}/>

    <div className={`ide ide-${fullIde ? 'full' : 'half'}`}>
      <div className="editor-and-terminal">
        {showEditor &&
          <Editor value={editorContent} readOnly={cantUseEditor}/>
        }
        <div className="terminal" style={{height: showEditor ? undefined : "100%"}}>
          <Shell/>
        </div>
      </div>
    </div>

    {specialMessages.map((message, index) =>
      <Popup
        key={index}
        open={true}
        onClose={() => closeSpecialMessage(index)}
      >
        <SpecialMessageModal message={message}/>
      </Popup>
    )}
  </>;
}
const SpecialMessageModal = ({message}) => (
  <div className="special-message-modal">
    <div dangerouslySetInnerHTML={{__html: message}}/>
  </div>
);

export const App = connect(
  state => ({
    ...state.book,
  }),
)(AppComponent);

