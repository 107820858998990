(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sync-message"), require("comlink"));
	else if(typeof define === 'function' && define.amd)
		define(["sync-message", "comlink"], factory);
	else if(typeof exports === 'object')
		exports["comsync"] = factory(require("sync-message"), require("comlink"));
	else
		root["comsync"] = factory(root["sync-message"], root["comlink"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__746__, __WEBPACK_EXTERNAL_MODULE__272__) {
return 