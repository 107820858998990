(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("comsync"), require("comlink"), require("pyodide"));
	else if(typeof define === 'function' && define.amd)
		define(["comsync", "comlink", "pyodide"], factory);
	else if(typeof exports === 'object')
		exports["pyodide-worker-runner"] = factory(require("comsync"), require("comlink"), require("pyodide"));
	else
		root["pyodide-worker-runner"] = factory(root["comsync"], root["comlink"], root["pyodide"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__422__, __WEBPACK_EXTERNAL_MODULE__272__, __WEBPACK_EXTERNAL_MODULE__28__) => {
return 